.office_card_switch {
  position: absolute;
  top: 0;
  right: -2px;
  padding: 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  /* height: calc(100% - 36px); */
  height: 100%;
  border-radius: 0 16px 0 0;
  /* background-color: rgba(25, 118, 210, .3); */
}

.office_card_btn_wrapper {
  position: absolute;
  top: 8px;
  right: 70px;
  background-color: white;
  border-radius: 25px;
}

.office_form_field_wrapper {
  padding: 4px 8px;
}