.info_field_wrapper {
  margin: 3px 0;
}

.info_field_name {
  display: inline-block;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #1976d2;
  transition: .3s ease-in-out;
}

.info_field_name.not_active {
  color: gray;
  transition: .3s ease-in-out;
}

.account_page_inner {
  display: flex;
}

.current_acc_active {  
  
  transform: translate3d(0, 0, 0);
  transition: transform .3s ease-in-out;
}

.current_acc_hide {  
  
  transform: translate3d(-150%, 0, 0);
  transition: transform .3s ease-in-out;
}

.all_acc_active {    
  width: calc(100vw - 32px);
  transform: translate3d(calc(-90vw + 28px), 0, 0);
  transition: transform .3s ease-in-out;
}

.all_acc_hide {    
  transform: translate3d(150%, 0, 0);
  transition: transform .3s ease-in-out;
}


