.start_wrapper {
  height: 100vh;
  background-color:rgb(25, 118, 210);
}

.start_first_block {
  position: relative;  
  height: 60%; 
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; 
  /* background-color: rgb(25, 118, 210); */
  /* clip-path: polygon(0 90%, 0 0, 100% 0, 100% 90%, 50% 100%); */
}

.start_h1 {
  margin: 200px 0 0 0; 
  text-align: center;
}

.start_second_block {   
  height: calc(40% - 60px); 
  background: #ffffff;
}