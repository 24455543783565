.page_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid rgba(128, 128, 128, .3);
  z-index: 10;
}

.header_inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding:  0 0;
  width: calc(100% - 68px);
}

.header_title {
  display: inline-block;  
  padding-top: 4px;
  width: 100%
}