.loader_wrapper {

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  transition: .3s ease-in-out;
}

.loader_wrapper.active {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  transition: .3s ease-in-out;
}