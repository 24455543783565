.nav_btn_content_wrapper {
  position: relative; 
  display: flex;
  justify-content: center;
  height: 40px;
  overflow: hidden;
  transition: .3s;
}

.nav_btn_text  {
  margin-top: 36px;
  animation: showText .3s;
  animation-fill-mode: forwards;
}

.nav_btn_text_none {
  margin-top: 13px;
}

.nav_btn_active_to_hide {
  margin-top: -2px;
  animation: hideText .3s;
  animation-fill-mode: forwards;
}

@keyframes showText {
  from { margin-top: 13px; }
  to { margin-top: -2px; }
}

@keyframes hideText {
  from { margin-top: -2px; }
  to { margin-top: 13px; }
}

