.modal_accounts_info_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal_accounts_text_wrapper {
  font-size: 14px;
  max-width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}